/**
 * This is the client-side entrypoint for your tRPC API. It is used to create the `api` object which
 * contains the Next.js App-wrapper, as well as your type-safe React Query hooks.
 *
 * We also create a few inference helpers for input and output types.
 */
import { createTRPCProxyClient, httpBatchLink, httpLink, loggerLink, unstable_httpBatchStreamLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import { type inferRouterInputs, type inferRouterOutputs } from '@trpc/server';
export { type File } from '@api/types/updates';
export { QueryClient, QueryClientProvider } from '@tanstack/react-query';
export { waitUntil } from '@vercel/functions';
import superjson from 'superjson';
import { z } from 'zod';
export type { LintMessage } from '@api/lib/Linter';

import { type AppRouter } from './router';
export { type AppRouter } from './router';
import { type IncomingHttpHeaders } from 'http';
import fetch from 'node-fetch';
export { type GenerateFromWebSchemaProps } from '@api/routers/generationsRouter';
export { type ReactProperty, type ReactPropertyWithSubPropertyBool } from '@ion/database/schema';
export { TRPCClientError } from '@trpc/client';

export const figmaLink = ({ fileKey, nodeId, fileName }: { fileKey: string; nodeId: string; fileName?: string }) =>
  `https://www.figma.com/design/${fileKey}${fileName ? `/${fileName}` : ''}?node-id=${nodeId}`;

const getBaseUrl = () => {
  if (typeof window !== 'undefined') {
    return '';
  } // browser should use relative url
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  } // SSR should use vercel url

  return `http://localhost:${process.env.PORT ?? 3000}`; // dev SSR should use localhost
};

export const FontLocationSchema = z.object({
  path: z.string(),
  content: z.string(),
  type: z.enum(['nextjs', 'vite']),
});

export type FontLocation = z.infer<typeof FontLocationSchema>;

export const createQueryClient = (baseUrl: string, vsCodeSessionId?: string | null) =>
  createTRPCProxyClient<AppRouter>({
    transformer: superjson,
    links: [
      httpLink({
        url: `${baseUrl}/api/trpc`,
        fetch(url, options) {
          if (url instanceof URL) {
            return fetch(url, {
              ...options,
            });
          }

          return fetch(url, {
            ...options,
          });
        },
        // You can pass any HTTP headers you wish here
        async headers() {
          return {
            'vs-code-session-id': vsCodeSessionId ?? undefined,
          };
        },
      }),
    ],
  });

export const createNextQueryClient = (baseUrl: string, headers: IncomingHttpHeaders) =>
  createTRPCProxyClient<AppRouter>({
    transformer: superjson,
    links: [
      httpLink({
        url: `${baseUrl}/api/trpc`,
        fetch(url, options) {
          if (url instanceof URL) {
            return fetch(url, {
              ...options,
            });
          }

          return fetch(url, {
            ...options,
          });
        },
        // You can pass any HTTP headers you wish here
        async headers() {
          return headers;
        },
      }),
    ],
  });

export const createStreamClient = (baseUrl: string, vsCodeSessionId?: string | null) =>
  createTRPCProxyClient<AppRouter>({
    transformer: superjson,
    links: [
      unstable_httpBatchStreamLink({
        url: `${baseUrl}/api/trpc`,
        // You can pass any HTTP headers you wish here
        async headers() {
          return {
            'vs-code-session-id': vsCodeSessionId ?? undefined,
          };
        },
      }),
    ],
  });

/** A set of type-safe react-query hooks for your tRPC API. */
export const api = createTRPCNext<AppRouter>({
  config() {
    return {
      /**
       * Transformer used for data de-serialization from the server.
       *
       * @see https://trpc.io/docs/data-transformers
       */
      transformer: superjson,

      /**
       * Links used to determine request flow from client to server.
       *
       * @see https://trpc.io/docs/links
       */
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === 'development' || (opts.direction === 'down' && opts.result instanceof Error),
        }),
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
        }),
      ],
    };
  },
  /**
   * Whether tRPC should await queries when server rendering pages.
   *
   * @see https://trpc.io/docs/nextjs#ssr-boolean-default-false
   */
  ssr: false,
});

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;
export { ION_ERROR, IonError, isIonError } from './lib/helpers/IonError';
export { httpBatchLink } from '@trpc/client';
export { superjson };

export const createFigmaLink = ({ figmaFileKey, nodeId }: { figmaFileKey: string; nodeId: string }) =>
  `https://www.figma.com/design/${figmaFileKey}?node-id=${nodeId}`;
